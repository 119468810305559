/**
 * Created by Maxim B. on 06/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
@import './variables';

$table-header-text-color: #000a12;
$default-padding: 16px;
$hover-row-background-color: rgba(224, 247, 250, 0.5);
$table-border-color: rgba(0, 10, 18, 0.16);

table.mat-table {
  padding: $default-padding;
  border: 1px solid $table-border-color;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;

  .mat-sort-header-sorted {
    .mat-sort-header-arrow {
      color: $green-statistic;
    }
  }


  thead {
    tr.mat-header-row {
      th.mat-header-cell {
        padding: 16px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $table-header-text-color;
        border-bottom: none;

        .table-title {
          margin-right: 10px;
        }
      }
    }
  }

  tbody {
    tr.mat-row {
      td.mat-cell {
        padding: 24px 16px;
        font-family: Roboto, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      &:hover {
        outline: 1px solid #fff;

        td.mat-cell {
          background-color: $hover-row-background-color;
          border-bottom: 1px solid #fff;
        }

        td.mat-cell:first-child {
          border-radius: 45px 0 0 45px;
        }

        td.mat-cell:last-child {
          border-radius: 0 45px 45px 0;
        }
      }
    }
  }

  tr.mat-row:last-child {
    td.mat-cell {
      border-bottom: none;
    }
  }

  tr.mat-row:first-child {
    td.mat-cell {
      border-top: none;
    }
  }
}
