
/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

@import '~@angular/material/theming';
@import 'variables';

@include mat-core();
$mat-primary:(
  50: #f2f7f8,
  100: #e5f0f1,
  200: #cce1e4,
  500: #cce1e4,
  700: #cce1e4,
  900: #006878,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
    900: $light-primary-text,
)
);

$mat-custom:(
  50: #f2f7f8,
  100: #e5f0f1,
  200: #cce1e4,
  500: #cce1e4,
  700: #cce1e4,
  900: #006878,
  contrast:(
    50: $dark-secondary-text,
    100: $dark-secondary-text,
    200: $dark-secondary-text,
    500: $dark-secondary-text,
    700: $dark-secondary-text,
    900: $dark-secondary-text,
  )
);

$mat-warning: (
  100: #F4C6B3,
  300: #F4C6B3,
  400: #ff7043,
  500: #DB4200,
  700: #DB4200,
  900: #EB8815,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
    900: $light-primary-text,
  )
);

$curriculum-theme-primary: mat-palette($mat-custom);
$curriculum-theme-accent: mat-palette($mat-custom);
$curriculum-theme-warn: mat-palette($mat-warning);

$curriculum-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar:    map_get($mat-grey, 100),
  background: #F2F7F8,
  hover:      rgba(black, 0.04),
  card:       white,
  dialog:     white,
  disabled-button: rgba(black, 0.12),
  raised-button: #006878,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
  tooltip: map_get($mat-grey, 700),
);

$curriculum-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $dark-secondary-text,
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

@function curriculum-light-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $curriculum-light-theme-foreground,
    background: $curriculum-light-theme-background,
  );
}

$curriculum-app-theme: curriculum-light-theme($curriculum-theme-primary, $curriculum-theme-accent, $curriculum-theme-warn);

@function mat-typography-config1(
  $font-family:       'Roboto, sans-serif',
  $display-4:         mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3:         mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2:         mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1:         mat-typography-level(34px, 40px, 400),
  $headline:          mat-typography-level(24px, 32px, 400),
  $title:             mat-typography-level(20px, 32px, 500),
  $subheading-2:      mat-typography-level(16px, 28px, 400),
  $subheading-2m:     mat-typography-level(16px, 28px, 400),
  $subheading-2l:     mat-typography-level(16px, 28px, 400),
  $subheading-1:      mat-typography-level(15px, 24px, 400),
  $subheading-1m:     mat-typography-level(15px, 24px, 400),
  $subheading-1w:     mat-typography-level(15px, 24px, 400),
  $subheading-1l:     mat-typography-level(15px, 24px, 400),
  $subheading-3om:    mat-typography-level(15px, 24px, 400),
  $subheading-3ou:    mat-typography-level(15px, 24px, 400),
  $body-3:            mat-typography-level(14px, 20px, 400),
  $body-3m:           mat-typography-level(14px, 20px, 400),
  $body-2:            mat-typography-level(14px, 24px, 500),
  $body-2m:           mat-typography-level(14px, 24px, 500),
  $body-1:            mat-typography-level(14px, 20px, 400),
  $body-1m:           mat-typography-level(14px, 20px, 400),
  $caption:           mat-typography-level(12px, 20px, 400),
  $caption-2:         mat-typography-level(12px, 20px, 400),
  $caption-3:         mat-typography-level(12px, 20px, 400),
  $button:            mat-typography-level(14px, 14px, 500),
  $button-2:          mat-typography-level(14px, 14px, 500),
  $button-3:          mat-typography-level(14px, 14px, 500),
  $button-4:          mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input:             mat-typography-level(inherit, 1.125, 400)
) {

  // Declare an initial map with all of the levels.
  $config: (
    display-4:         $display-4,
    display-3:         $display-3,
    display-2:         $display-2,
    display-1:         $display-1,
    headline:          $headline,
    title:             $title,
    subheading-2:      $subheading-2,
    subheading-2m:     $subheading-2m,
    subheading-2l:     $subheading-2l,
    subheading-1:      $subheading-1,
    subheading-1m:     $subheading-1m,
    subheading-1w:     $subheading-1w,
    subheading-1l:     $subheading-1l,
    subheading-3om:    $subheading-3om,
    subheading-3ou:    $subheading-3ou,
    body-3:            $body-3,
    body-3m:           $body-3m,
    body-2:            $body-2,
    body-2m:           $body-2m,
    body-1:            $body-1,
    body-1m:           $body-1m,
    caption:           $caption,
    caption-2:         $caption-2,
    caption-3:         $caption-3,
    button:            $button,
    button-2:          $button-2,
    button-3:          $button-3,
    button-4:          $button-4,
);

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      $new-level: map-merge($level, (font-family: $font-family));
      $config: map-merge($config, ($key: $new-level));
    }
  }

  // Add the base font family to the config.
  @return map-merge($config, (font-family: $font-family));
}

$custom-typography1: mat-typography-config1(
  $display-4: mat-typography-level(96px, 112px, 300),                        //.mat-display-4
  $display-3: mat-typography-level(60px, 72px, 300),                         //.mat-display-3
  $display-2: mat-typography-level(48px, 56px, 400),                         //.mat-display-2
  $display-1: mat-typography-level(34px, 36px, 400),                         //.mat-display-1
  $headline: mat-typography-level(24px, 28px, 500),                          //.mat-h1
  $title: mat-typography-level(20px, 24px, 500),                             //.mat-h2
  $subheading-2: mat-typography-level(16px, 24px, 400, 0.15px ),             //.mat-h3
  $subheading-2m: mat-typography-level(16px, 24px, 500, 0.15px),             //.mat-h3-m
  $subheading-2l: mat-typography-level(16px, 24px, 400, 0.15px),             //.mat-h3-l
  $subheading-1: mat-typography-level(14px, 16px, 400, 0.1px),               //.mat-h4
  $subheading-1m: mat-typography-level(14px, 16px, 500, 0.1px),              //.mat-h4-m
  $subheading-1w: mat-typography-level(14px, 24px, 700, #DB4200, 0.1px),     //.mat-h4-w
  $subheading-1l: mat-typography-level(14px, 16px, 400, underline, 0.1px),   //.mat-h4-l
  $subheading-3om: mat-typography-level(10px, 16px, 500, 1.5px),             //.mat-h5-om
  $subheading-3ou: mat-typography-level(10px, 16px, 500, underline, 1.5px),   //.mat-h5-ou
  $body-1: mat-typography-level(16px, 24px, normal, null, 0.5px),             //.mat-body-1
  $body-1m: mat-typography-level(16px, 24px, 500, null, 0.5px),               //.mat-body-1-m
  $body-2: mat-typography-level(14px, 20px, normal, null, 0.25px),            //.mat-body-2
  $body-2m: mat-typography-level(14px, 20px, 500, null, 0.25px),              //.mat-body-2-m
  $body-3: mat-typography-level(12px, 18px, normal, null, 0.25px),            //.mat-body-3
  $body-3m: mat-typography-level(12px, 18px, 500, null, 0.25px),              //.mat-body-3-m
  $caption: mat-typography-level(12px, 16px, normal, null, 0.4px),            //.mat-caption
  $caption-2: mat-typography-level(10px, 15px, normal, null, 0.4px),          //.mat-caption-2
  $caption-3: mat-typography-level(8px, 15px, normal, null, 0.4px),           //.mat-caption-3
  $button: mat-typography-level(14px, 16px, 500, 1.25px),                     ///.mat-button
  $button-2: mat-typography-level(14px, 16px, 500, 1.25px),                   ///.mat-button-2
  $button-3: mat-typography-level(10px, 12px, 500, 1.25px),                   ///.mat-button-3
  $button-4: mat-typography-level(10px, 12px, 500, 1.25px),                   ///.mat-button-4
);


@include mat-base-typography($custom-typography1);
@include angular-material-theme($curriculum-app-theme);

.overline{
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.overline-without-upper-case{
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
}

.button{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
}
.scrollbar{
  &::-webkit-scrollbar {
    width: 4px;
  }
}

.light-theme{
  .high-emphasis{
    color: $black;
  }
  .medium-emphasis{
    color: $dark-grey;
  }
  .disabled-emphasis{
    color: $light-grey;
  }
  .header{
    background-color: $white;
  }
  .active-text{
    color: mat-color($curriculum-theme-primary, default);
  }
  .border-bottom{
    border-bottom: 4px solid mat-color($curriculum-theme-primary, default)
  }

  .glow{
    background-color: $main-color100;
  }

  .glow-secondary{
    background-color: mat-color($curriculum-theme-accent, 50)
  }

  .green-statistic{
    stroke: #006878;
  }

  .green-statistic-dot{
    fill: #006878;
    stroke: white;
  }

  .green-statistic-text{
    color: $green-statistic;
  }

  .red-statistic{
    stroke: $red-statistic;
  }

  .red-statistic-dot{
    fill: $red-statistic;
    stroke: white;
  }
  .red-statistic-text{
    color: $red-statistic;
  }

  .statistic-predicted{
    stroke: #5DB605;
    filter: drop-shadow(0px 0px 8px#5DB605);

  }


  .primary-statistic-dot{
    fill: mat-color($curriculum-theme-primary, default);
  }

  .primary-statistic-text{
    color: mat-color($curriculum-theme-primary, default);
  }

  .primary-statistic-light{
    stroke: $light-statistic;
  }

  .primary-statistic-light-dot{
    fill: $light-statistic;
    stroke: white;
  }

  .statistic-caption{
    color: $black;
  }
  .scrollbar::-webkit-scrollbar-thumb{
    background-color: $light-grey;
  }
}

.dark-theme{
  background-color: $black;
}

// ngx-image-cropper style override.
:root {
  --cropper-outline-color: rgb(255, 255, 255, .7);
}






