/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@import '~@angular/material/theming';
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat-core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//$curriculum-primary: mat-palette($mat-indigo);
//$curriculum-accent: mat-palette($mat-pink, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$curriculum-warn: mat-palette($mat-red);
//
//// Create the theme object (a Sass map containing all of the palettes).
//$curriculum-theme: mat-light-theme($curriculum-primary, $curriculum-accent, $curriculum-warn);
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include angular-material-theme($curriculum-theme);
//
///* You can add global styles to this file, and also import other style files */

@import './styling/table';
@import '~ng-pick-datetime-ex/assets/style/picker.min.css';
@import '~igniteui-angular/lib/core/styles/themes/index';
@import './styling/gantt';
@import './styling/variables';
@import '~ngx-toastr/toastr';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
html,
body {
  min-height: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
  position: relative;
  font-family: Roboto, sans-serif;
  -webkit-touch-callout: none; //disable text selection highlighting
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Scroll customization
//* {
//  scrollbar-width: thin;
//  scrollbar-color: $main-color200 $main-color200;
//}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

//*::-webkit-scrollbar-track {
//  background: $main-color200;
//}

*::-webkit-scrollbar-thumb {
  background-color: var(--theme-color-primary-5);
  border-radius: 4px;
  &:hover {
    cursor: pointer
  }
}
// End scroll customization

//margins and paddings. e.g: .m-b-8 .p-t-16 .m-l-xs-16
$spaceamounts: (0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80);
$sides: (top, bottom, left, right);

.router-outlet.router-flex + * {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    @media (max-width: $xs) {
      body .m-#{str-slice($side, 0, 1)}-xs-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      body .p-#{str-slice($side, 0, 1)}-xs-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

img[src*='base'] {
  max-width: 500px;
}

.Wirisformula {
  width: auto !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-centered {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-line-height {
  line-height: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
}

mat-card.rounded-border {
  border: 1px solid rgba(0, 10, 18, 0.16);
  border-radius: 8px;
  box-shadow: none !important;
}

.custom-ink-size {
  &.mat-tab-group.mat-primary .mat-ink-bar,
  &.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 4px;
  }
}

.full-width {
  width: 100%;
}

.side-m-8 {
  margin: 0 8px;
}

.hidden-y-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

@media only screen and (max-device-width: $xs) {
  .container.mob-container {
    padding: 24px 16px 0 16px !important;
  }
}

.arrow-link {
  text-decoration: none;

  &:active,
  &:visited {
    color: $black;
  }

  &:hover {
    cursor: pointer;
  }
}

.default-card-border-view {
  background-color: $white;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.hover-cursor:hover {
  cursor: pointer;
}

.image-note-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;
  width: min-content;
}

.height-unset {
  height: unset;
}

.highlight-animation {
  -webkit-box-shadow: 0px 0px 29px 2px var(--theme-color-primary-5);
  -moz-box-shadow: 0px 0px 29px 2px var(--theme-color-primary-5);
  box-shadow: 0px 0px 29px 2px var(--theme-color-primary-5);
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  from {
    -webkit-box-shadow: 0px 0px 0px -200px rgba(158, 165, 171, 1);
    -moz-box-shadow: 0px 0px 0px -200px rgba(158, 165, 171, 1);
    box-shadow: 0px 0px 0px -200px rgba(158, 165, 171, 1);
  }
}

a.link:hover {
  cursor: pointer;
}

.nav-container-sow {
  position: relative;
  height: 44px;
}

#nav-sow {
  background-color: white;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-shadow-sow {
  -webkit-box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.36);
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.36);
  padding: 0 4px;
  border-radius: 4px;
}

.disable-absolute {
  position: unset !important;
}

div.student-chart-tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 28px;
  padding: 2px;
  font-size: 12px;
  background: lightgray;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}

// Perfect scroll
.ps {
  &:hover {
    & > .ps__rail-y,
    & > .ps__rail-x {
      opacity: 1;
    }
  }
  .ps__rail-x,
  .ps__rail-y {
    width: 4px;
    background-color: transparent;
    &.ps--clicking {
      background-color: transparent;
    }
    .ps__thumb-y,
    .ps__thumb-x {
      width: 4px;
      background-color: #647077;
      border-radius: 2px;
      right: 0;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      opacity: 1;
      & > .ps__thumb-y,
      & > .ps__thumb-x {
        width: 4px;
      }
    }
  }
}

// TODO: check if we can leave it here & remove from other files
.link {
  text-decoration: underline;
  color: #006878;
}

@media screen and (max-width: 959px) {
  .book-flip-image {
    width: 190px;
    margin-bottom: 40px;
  }
}

//

.book {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  perspective: -600px;

  &-page {
    width: 50%;
    height: 100%;
    box-sizing: border-box;

    background-color: #fff;

    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25), inset 0 0 24px 18px rgba(0, 104, 120, 0.12),
      inset 0 0 8px 4px rgba(0, 104, 120, 0.06);

    &-start {
      transform-origin: 100% 100%;
      border-right: 1px solid #d7d8da;
      padding: 24px;
    }

    &-end {
      transform-origin: 0 0;
      border-left: 1px solid #d7d8da;
      &.opened {
        transform: rotateY(-180deg);
      }
    }
  }
}

.logo {
  width: unset;
  height: unset;
  transform: scale(0.8);
}

.owl-dt-popup.cdk-overlay-pane {
  top: unset !important;
}

@media screen and (max-width: 959px) {
  .book {
    height: unset;

    &-block {
      height: auto !important;
    }

    &-page {
      width: 100%;
      box-shadow: none;
      box-sizing: border-box;
      border: none !important;
      align-items: center;
      height: 100vh;
      justify-content: center;
      display: flex;
      flex-direction: column;

      &-end {
        // padding-top: 100px;
      }
    }
  }

  .book-flip-image {
    width: 190px !important;
  }

  .logo {
    margin: 24px 0;
  }
}

::ng-deep {
  .toast-container .ngx-toastr {
    width: auto;
  }
  .toast-message-tutor-session {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .go {
      margin-top: 10px;
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: $white;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (orientation: landscape) {
  @media screen and (max-width: 959px) {
    .book-page{
      height: 100%;
    }
  }
}
