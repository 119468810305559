/**
 * Created by Alex Poh. on 26/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

@import 'variables';

div.gantt_layout_root {
  border-radius: 8px;
}

div.gantt_grid_data .gantt_row.gantt_selected,
div.gantt_grid_data .gantt_row.odd.gantt_selected {
  background-color: var(--theme-color-primary-0);
}

div.gantt_task_row.gantt_selected {
  background-color: var(--theme-color-primary-0);
}

div.gantt_task_line {
  background-color: $light-grey;
  border-color: $light-grey;
}

div.gantt_task_cell,
div.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: rgb(206, 206, 206);
}

div.gantt_task_line.gantt_selected {
  background-color: var(--theme-color-primary-4);
  border-color: var(--theme-color-primary-4);
}

div.gantt_grid_data .gantt_row:not(.gantt_selected):hover,
div.gantt_grid_data .gantt_row.odd:not(.gantt_selected):hover {
  background-color: var(--theme-color-primary-0);
}

.gantt_task_progress_drag {
  display: none !important;
}

.gantt_grid_head_cell.gantt_grid_head_text.gantt_last_cell {
  color: var(--theme-color-primary-5);
  text-align: start;
  padding-left: 6px;
}

.left-row.mat-body-2 {
  line-height: 2.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.left-row.mat-body-2.title {
  font-weight: bold;
}

div.gantt_line_wrapper div {
  background-color: var(--theme-color-accent-4);
}
div.gantt_link_arrow_right {
  border-left-color: var(--theme-color-accent-4);
}

div.gantt_task_scale div.gantt_scale_line:last-child {
  display: none; //removing last scale
}

div.gantt_scale_cell.lesson_sign {
  margin-top: 5px;
}
