/**
 * Created by Alex Poh. on 23/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

$white: #ffffff;
$black: #000000;
$dark-black: #000000;
$dark-black2: #000A12;
$dark-grey: #263238;
$dark-grey2: #393B47;
$dark-grey3: #484848;
$medium-grey: #414d55;
$light-grey: #657581;
$light-grey2: #647077;
$light-grey3: #888991;
$main-color: #006878;
$main-color200: #CCE1E4;
$main-color100: #E5F0F1;
$main-color70: #E0F7FA;
$main-color50: #F2F7F8;
$main-color20: #FAFAFA;
$main-dark: #005360;

$green-right-answer: #4eb653;
$green-statistic: #5DB605;
$green-btn: #007100;
$red-statistic: #DB4200;
$red-statistic2: #C75D00;
$yellow-progress: #E7C507;
$light-statistic: #56c7d8;
$red-error:#DB4200;
$orange-wrong-answer: #EB8815;
$cancel-btn: #b00020;
$error-bgc: rgba(176, 0, 32, 0.1);
$purple-calendar: #7986CB;
$warn-color: #e04845;
$warn-color2: #C43F3D;

$green-calendar: #33B679;
$yellow-calendar: #E4C441;
$yellow-calendar2: #FBB500;
$light-green: #eff8e6;
$light-yellow: #FAF3CD;
$yellow-notice-msg: #F3E283;
$checkedRadioColor: rgba(0, 104, 120, 0.38);

////Text color/////
$grey-calendar-text:#9C9EB1;
$heading: #393D64;
$title: #080C3D;
$title-regular: #60626C;

$default-font-family: Roboto, serif;
$grey-checkbox: #CECED8;
$button-play-background: #006878;
$card-border-color: rgba(0, 10, 18, 0.16);
$disabled-card-color: rgba(0, 0, 0, 0.38);
$disabled-btn-color: rgba(0, 10, 18, 0.12);
$chips-color: rgba(77, 162, 201, 0.2);
$card-hover-background-color: rgba(224, 247, 250, 0.3);
$card-border-radius: 8px;
$border-color-grey: #000a1229;
$supported-text-color: #9fa4a8;
$card-green-background: #EFF8E6;
$unCheckedDotColor: #F5F5F6;

// Paddings
$padding-16: 16px;

// Margins
$margin-b-8: 8px;
$margin-16: 16px;

//Responsive breakpoints for angular flex
$xs: 599px;

